import PropTypes from "prop-types"
import React from "react"

const _ = require("lodash")

const TagList = ({ tags }) => (
  <>
    {tags.map((tag) => {
      return (
        <div class="button uppercut">
          <a href={`/tags/${_.kebabCase(tag)}`}>{tag}</a>
        </div>
      )
    })}
  </>
)

TagList.propTypes = {
  tags: PropTypes.array,
}

TagList.defaultProps = {
  tags: [],
}

export default TagList
